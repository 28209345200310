.colOthResCode {
    margin-top: -42px;
    height:10px!important;
    padding:0!important;
}

.colOthResCode fieldset {
    background-color: transparent;
    width:70px;
}

.colOthResCode .MuiInputBase-root{
    background-color: transparent!important;
}

.creditMemo{
    padding-top:20px!important;
}

.od-remark-box{
    box-shadow: none!important;
    border: 1px solid lightgray;
    padding: 0 8px 4px 8px!important;
    margin: 0;
}

#od-remark-label{
    transform: translateY(-100%);
    background-color: white;
    padding: 0 4px;
    display: inline-block;
    font-size: 12px!important;
    color:#2d76a6!important;    
    margin-left: -6px;
}

#id-remark-input{
    box-shadow: none;
    border: none; 
    margin-top: -8px;
    width:100%!important;
}

#btnProceed{
    background-color: #7066e0;
    color: white;
}