.template-card button.pureBtn {
    padding: 15px 10px;
    cursor: pointer;
    text-transform: initial;
    font-weight: 500;
    border: 1px solid rgb(205 205 205);
    border-width: 1px 0;
    outline: none;
}
.template-card  input[type="radio"] {
    margin: -1px 10px 0 0;
}
.template-card textarea,
.template-card select,
.template-card select *,
.template-card input[type='text'],
.template-card input[type='password'] {
    outline: none;
    background: none;
    display: block;
    border: 1px solid rgb(196 196 196);
    padding: 10px 0;
    border-width: 0 0 1px;
}
.template-card label {
    font-weight: 400;
}

/* Template - Card */
.template-card main > section > div:nth-child(1) {
    display: flex;
    align-items: center;
    margin: 15px -0;
    justify-content: space-between;
}
.template-card main > section > div:nth-child(1) > h1 {
    margin: 0;
}
.template-card main > section > div:nth-child(1) > div {
    margin: 0 -5px;
}
.request-meta-data {
    margin: 15px 0;
    border-collapse: collapse;
    background: rgb(255 255 255);
    border-radius: 4px;
    width: 100%;
    padding: 15px;
}
.request-meta-data > h2 {
    margin: 15px 0;
}
/*.request-meta-data > div.form {*/
/*    display: flex;*/
/*    align-items: self-start;*/
/*}*/
/*.request-meta-data > div.form > div {*/
/*    width: 50%;*/
/*    margin: 0 30px;*/
/*}*/
.request-meta-data > div.form > div > div {
    display: flex;
    align-items: center;
    margin: 0 0 15px;
}
/*.request-meta-data > div.form > div > div > label {*/
/*    width: 250px;*/
/*}*/
/*.request-meta-data > div.form > div > div > *:not(label) {*/
/*    flex: 1;*/
/*}*/
/* Template - List */
.template-list main > section > div:nth-child(1) {
    display: flex;
    align-items: center;
    margin: 15px 0;
    justify-content: space-between;
}
.template-list main > section > div:nth-child(1) > h1 {
    margin: 0;
}
.template-list main > section > div:nth-child(1) > div {
    margin: 0 -5px;
}
.template-list main > section > div:nth-child(2) {
    display: flex;
    align-items: center;
    margin: 15px -5px;
}
.template-list main > section > div:nth-child(2) > div {
    display: flex;
    align-items: center;
    margin: 0 5px;
}
.template-list main > section > div:nth-child(2) > nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    margin: 0 5px;
}
.template-list main > section > div:nth-child(2) > nav > div {
    display: flex;
    align-items: center;
}
.template-list main > section > div:nth-child(2) > nav > div > a {
    padding: 15px 5px;
    margin: 0 5px;
    text-decoration: none;
}
.template-list main > section input {
    border: 1px solid rgb(0 0 0 / 0.05);
    border-radius: 4px;
    padding: 10px;
    background: rgb(255 255 255 / 0.5);
}
.template-list main > section select {
    border: 1px solid rgb(0 0 0 / 0.05);
    border-radius: 4px;
    padding: 10px;
    background: rgb(255 255 255 / 0.5);
}
.template-list main > section label {
    padding: 0 5px;
}

/* Component */
.search {
    position: relative;
}
.search > i {
    position: absolute;
    pointer-events: none;
    cursor: pointer;
    padding: 10px;
}
.search input {
    padding-left: 35px;
}
.button {
    display: inline-block;
    margin: 0 5px;
    padding: 10px;
    background: rgb(253 185 40);
    text-decoration: none;
    border-radius: 4px;
    /*
    box-shadow: 0 0 5px -2.5px rgb(0 91 170 / 0.75);
    box-shadow: 0 0 5px 0 rgb(0 0 0 / 0.15);
    */
}
.button.secondary {
    background: rgb(247 247 247);
    box-shadow: none;
}
.button.sub {
    padding: 10px 15px;
    background: rgb(253 185 40 / 0.25);
}
.button.sub-disabled {
    padding: 10px 15px;
    background: rgb(247 247 247);
    cursor: not-allowed;
    color: rgb(0 0 0 / 0.25);
}


body {
    background-color: red;
}