.MuiDateRangeCalendar-root > :first-child {
  color: rgb(130 130 130 / 0%) !important;
  bottom: 0 !important;
}

.editor h2 {
  font-size: 24px;
}

.editor h3 {
  font-size: 18px;
}

.editor h4 {
  font-size: 16px;
}

.editor strong,
b {
  font-weight: bold;
}

.editor a {
  color: blue;
}
