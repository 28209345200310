.pnl-form {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid rgba(0, 0, 0, 1);
    padding: 32px 16px;
    position: relative;
}

.pnl-form table {
    border: 1px solid rgba(200, 200, 200, 1);
    width: 100%;
    border-collapse: collapse;
}

.pnl-form th {
    padding: 4px 8px;
    border: 1px solid rgba(200, 200, 200, 1);
    height: 30px;
    text-align: left;
    -webkit-text-stroke: .5px;
}

.pnl-form td {
    padding: 4px 8px;
    border: 1px solid rgba(200, 200, 200, 1);
    height: 30px;
}

.pnl-form h1 {
    font-size: 18pt;
    color: rgba(33, 111, 160, 1);
    width: 100%;
    text-align: center;
    padding: 8px 0 16px;
    margin: 0;
    -webkit-text-stroke: .5px;
}

.pnl-form h2 {
    font-size: 13pt;
    width: 100%;
    text-align: center;
    padding: 8px 0;
    margin: 0;
    -webkit-text-stroke: .5px;
}

.pnl-form h3 {
    margin: 0;
    padding: 0;
    -webkit-text-stroke: .5px;
}

.pnl-form dl {
    display: flex;
    flex-wrap: wrap;
    margin: 16px 0;
}

.pnl-form dl>dt {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 0 16px 4px 0;
}

.pnl-form dl>dt>label {
    width: 150px;
}

.pnl-form dl>dt>label+div {
    width: calc(100% - 150px);
    position: relative;
}

.pnl-form dl>dt>label+div:before {
    content: ':';
    position: absolute;
    left: -8px;
}

.pnl-form .sc-01 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}

.pnl-form .sc-01 h2 {
    padding: 8px 0 16px;
}

.pnl-form .sc-01 dl {
    margin: 0;
}

.pnl-form .sc-01 dl:nth-of-type(1) {
    width: calc(84% - 2px);
    justify-content: space-between;
    border-left: 1px solid rgba(200, 200, 200, 1);
}

.pnl-form .sc-01 dl:nth-of-type(2) {
    width: 16%;
    position: relative;
}

.pnl-form .sc-01 dl>dt {
    width: 100%;
    padding: 8px;
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
    border: 1px solid rgba(200, 200, 200, 1);
    position: relative;
}

.pnl-form .sc-01 dl>dt>* {
    text-align: center;
}

.pnl-form .sc-01 dl>dt>label {
    padding: 4px 0 0;
    width: 100%;
    border-top: 1px dashed rgba(0, 0, 0, 1);
    font-size: 9pt;
}

.pnl-form .sc-01 dl>dt>[data-name='approver'] {
    padding: 4px 0;
    min-height: 125px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.pnl-form .sc-01 dl>dt>[data-name='approver']:has(>label) {
    justify-content: center;
}

.pnl-form .sc-01 dl>dt>[data-name='approver']>span {
    font-size: 9pt;
}

.pnl-form .sc-01 dl>dt>[data-name='approver']>img {
    width: 100%;
}

.pnl-form .sc-01 dl:nth-of-type(1)>dt {
    width: 20%;
    border-width: 1px 1px 1px 0;
}

.pnl-form .sc-01 dl:nth-of-type(2)>dt:has(.btn-sign) {
    border: 2px dashed rgba(244, 67, 54, .75);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pnl-form .sc-01 dl:nth-of-type(2)>dt:has(.btn-sign) * {
    color: rgba(244, 67, 54, .75);
}

.pnl-form .sc-01.sc-float {
    padding: 8px 0 16px;
}

.pnl-form .sc-01.sc-float dl {
    justify-content: flex-end;
}

.pnl-form .sc-01.sc-float dl>dt {
    padding: 0 8px;
}

.pnl-form .sc-01.sc-float dl,
.pnl-form .sc-01.sc-float dl>dt {
    border: none;
}

.pnl-form .sc-01.sc-float dl>dt>[data-name='approver']>img {
    margin: 0 0 4px;
    border-bottom: 1px dashed rgba(0, 0, 0, 1);
}

.pnl-form .sc-02 {
    display: flex;
    flex-direction: initial;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    border-top: 1px solid rgba(200, 200, 200, 1);
    padding: 16px 0;
}

.pnl-form .sc-02 dl {
    width: 50%;
}

.pnl-form .sc-03 {
    width: 100%;
}

.pnl-form .sc-03 tr>*:nth-child(1) {
    width: 60px;
    text-align: center;
}

.pnl-form .sc-03 tr>*:nth-child(2) {
    text-align: left;
}

.pnl-form .sc-03 tr>*:nth-child(3) {
    width: 150px;
    text-align: right;
}

.pnl-form .sc-03 tr>*:nth-child(4) {
    width: 300px;
    text-align: left;
}

.pnl-form .sc-04 {
    width: calc(100% - 451px);
}

.pnl-form .sc-05 {
    width: 451px;
    padding: 16px 0 0;
}

.pnl-form .sc-05>table>tr>th {
    width: 150px;
}

.pnl-form .sc-05>table>tr>td>div {
    display: flex;
    justify-content: space-between;
}

.pnl-form .sc-05>table>tr>td>div:not(:empty):before {
    content: 'S$';
}

.pnl-document {
    width: 100%;
    border: none;
    height: 800px;
}

.verifier-label {
    color: gold !important;
}