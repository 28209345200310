.dish-comment {
    font-weight: 300 !important;
}

.dish-name {
    white-space: pre-wrap;
}

.chef-name {
    width: 100%;
    display: inline-block;
    font-size: 9pt !important;
}

.star {
    width: 60px !important;
    white-space: pre-wrap;
    text-align: center;
}

.star-percent {
    width: 100%;
    display: inline-block;
    font-size: 9pt !important;
}