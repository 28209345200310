.hide {
    display: none !important;
}

img.show {
    height: 37px;
    width: 37px;
    border-radius: 2px;
}

/* Set pointer color to blue */
.css-1ua17lr {
    background-color: skyblue !important;
}

/* Set pointer head color to blue */
.css-1gcpqpz {
    border-color: skyblue !important;
    background-color: skyblue !important;
}