.hide {
    display: none !important;
}

img.show {
    height: 37px;
    width: 37px;
    border-radius: 2px;
}

.store-config, .receipt-footer, .billing-address{
    width:100%!important;
}
