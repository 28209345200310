@media screen and (max-width: 896px) {
  #label-file-upload{
    display: none!important;
  }

  .form-file-upload{
    margin-left:23px;
  }

  .custom-grid-container{
    padding-top: 2px!important;
    padding-bottom: 2px!important;
  }
}

@media screen and (min-width: 897px) {
  .form-file-upload{
    margin-left:32px;
  }

  .custom-grid-container{
    padding-top: 11px!important;
    padding-bottom: 11px!important;
  }
}

#photo_suggestion{
  color:red;
  font-size:13px;
  width:100%; 
  text-align: left;
}

.photo_label{
  font-size: 12px;
  font-weight: normal;
  font-style: italic;
}

.divImageContainer{
  display: block;
  margin-top:auto;
  margin-bottom: auto;
  height:250px;
  padding:0px!important;
}

.btnRemoveImage{
  position: absolute;
  margin:5px 0px 0px 5px;
  color:red!important;
  background-color: lightgray;
  opacity: 0.7;
}

.img_preview{
  width: 100%!important;
  height: 100%!important;
  object-fit: cover;
}

.hide-this-component{
  display: none!important;
}

.add-button-sub-action{
  margin-top:10px;
}

.form-file-upload {
  margin-top:60px;
  width: 100%;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-style: solid;
  border-color: #cbd5e1;
  background-color: #f8fafc;
  margin: 10px 10px 35px 10px;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.disabled_color{
  color:gray!important;
  background-color:gray!important;
}