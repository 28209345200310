.colResType {
    margin-top: 0!important;
    height:10px!important;
    padding:0!important;
}

.colResType fieldset {
    background-color: transparent;
    width:70px;
}

.colResType .MuiInputBase-root{
    background-color: transparent!important;
}

.hide_this{
    display: none!important;
}

.hide_text input{
    color: white!important;
}
